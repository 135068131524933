<template>
	<section id="mainView">
		<div class="main-cont">
			<div class="cont-header">
				<h2>Contact Messages</h2>
				<button class="logout-button" @click="logout()">Logout</button>
			</div>
			<div class="messages-cont">
				<div v-if="messages.length <= 0" class="no-messages">No Messages</div>
				<ContactMessage
					v-for="message in messages"
					:key="message.id"
					:firstName="message.first_name"
					:lastName="message.last_name"
					:company="message.company"
					:email="message.email"
					:phone="message.phone"
					:contactMethod="message.pref_cont_method"
					:message="message.message"
					:dateSubmitted="message.date_submitted"
					:id="message.id"
					@delete-post="removePost(message.id)"
				/>
			</div>
		</div>
	</section>
</template>

<script>
// Dependency Imports
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { api } from '@/plugins/wire-config.js';
import { mainStore } from '@/store/main';

// Component Imports
import ContactMessage from '@/components/ContactMessage.vue';

export default {
	name: 'AdminPanel',
	components: {
		ContactMessage,
	},

	setup() {
		let router = useRouter();
		const messages = ref([]);

		async function logout() {
			let response = await api.delete('/logout', (err) => {
				console.error(err);
			});

			if (response._ok) {
				await mainStore.updateSessionData();
				return router.push({ name: 'Login' });
			}

			console.error(response._message);
		}

		function removePost(id) {
			let newArr = messages.value.filter((msg) => {
				if (msg.id != id) {
					return msg;
				}
			});
			messages.value = newArr;
		}

		onBeforeMount(async () => {
			let messagesData = await api.get('/contact-messages', (err) => {
				throw err;
			});
			messages.value = messagesData;
		});

		// Setup Returns
		return {
			logout,
			messages,
			removePost,
		};
	},

	async beforeRouteEnter(to, from, next) {
		await mainStore.updateSessionData();
		if (!mainStore.getState().session.isAuth) {
			next({ name: 'Login' });
		} else {
			next();
		}
	},
};
</script>

<style scoped>
#mainView {
	/* border: solid yellow 1px; */
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: auto;
	min-height: 100%;
	position: relative;
	z-index: 0;
}

.main-cont {
	position: relative;
	width: 90%;
	height: 100%;
	min-height: 640px;
	display: grid;
	grid-template-rows: 90px auto;
	gap: 2px;
	border-radius: 40px;
	background: rgba(255, 255, 255, 0.5);
	background: var(--card-gradient-contrast);
	box-shadow: var(--card-shadow);
	color: var(--text-color);
	border: solid green 0px;
	margin: 40px 0px 0px 0px;
	backdrop-filter: blur(10px);
}

.cont-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	grid-row: auto / span 1;
	border-bottom: solid rgba(125, 125, 125, 0.3) 1px;
	padding: 0px 32px 0px 40px;
}

h2 {
	margin: 6px 0px 0px 0px;
	display: flex;
	text-align: left;
}

.logout-button {
	max-width: 120px;
	width: 90%;
	height: 44px;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	border-radius: 60px;
	color: rgba(255, 255, 255, 0.9);
	background: linear-gradient(
		145deg,
		rgba(0, 172, 255, 1) 0%,
		rgba(179, 0, 255, 1) 100%
	);
	background-size: 200%;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	transition: cubic-bezier(0.56, 0, 0.39, 2.5) 230ms;
	border: none;
}

.logout-button:focus {
	border: solid white 2px;
	outline: none;
}

.logout-button:hover {
	transform: translateY(-3px);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
}

.logout-button:active {
	transform: translateY(2px);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.messages-cont {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
}

.no-messages {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}
</style>
