<template>
	<div class="comp-cont">
		<button @click="deleteMessage()">X</button>
		<div class="message-cont">
			<div class="from">
				<div>
					From: <span>{{ firstName }} {{ lastName }}</span>
				</div>
				<div>
					Company: <span>{{ company }}</span>
				</div>
				<div>
					Submitted on: <span>{{ dateSubmitted }}</span>
				</div>
			</div>
			<div class="contact-point">
				<div>
					Email: <span>{{ email }}</span>
				</div>
				<div>
					Phone: <span>{{ phone }}</span>
				</div>
				<div>
					Contact By: <span>{{ contactMethod }}</span>
				</div>
			</div>
			<div class="message">
				{{ message }}
			</div>
		</div>
	</div>
</template>

<script>
// Dependency Imports
import {} from 'vue';
import { api } from '@/plugins/wire-config.js';

//Component Imports

export default {
	name: 'ContactMessage',
	components: {},
	props: {
		firstName: String,
		lastName: String,
		company: String,
		email: String,
		phone: String,
		contactMethod: String,
		message: String,
		dateSubmitted: String,
		id: Number,
	},

	setup(props, context) {
		async function deleteMessage() {
			await api.delete(`/contact-messages/${props.id}`);
			context.emit('delete-post');
		}

		return {
			deleteMessage,
		};
	},
};
</script>

<style scoped>
.comp-cont {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap-reverse;
	align-items: center;
	border-bottom: solid rgba(125, 125, 125, 0.3) 1px;
	/* border: solid blue 1px; */
}

.message-cont {
	width: 90%;
	min-height: 90px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 30px 20px 5px 20px;
	font-size: 14px;
	flex-wrap: wrap;
}

.from {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	max-width: 400px;
	/* border: solid blue 1px; */
}

.contact-point {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	max-width: 400px;
	width: 100%;
	/* border: solid blue 1px; */
}

.message {
	margin: 10px;
	justify-self: right;
	width: 100%;
	margin: 14px 0px 14px 0px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-align: left;
	/* border: solid blue 1px; */
}

span {
	font-weight: bold;
	margin: 0px;
	font-size: 16px;
}

button {
	width: 30px;
	height: 30px;
	border-radius: 15px;
	margin: 0px 20px 20px 20px;
	border: none;
	cursor: pointer;
	outline: inherit;
	font-weight: bold;
	background-color: rgba(255, 255, 255, 0);
	opacity: 0.3;
	transition: all linear 100ms;
	color: var(--text-color);
}

button:hover {
	opacity: 1;
	background-color: rgba(255, 0, 0, 0.7);
}
</style>
